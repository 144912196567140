import router from "@/entries/front/router";
import { clientWithoutAuth } from "@/entries/front/axios";
import { entity, setOptionRefreshTrigger} from "./migration_base";
import utility, { concatModules } from "@/utility";

const initialEntity = {
  count: null,
  accountInfo: {
    accountId: null,
    mailAddress: "",
    lastName: "",
    firstName: "",
    phoneNumberInfo: {
      phoneNumber: "",
      phoneCountryCode: ""
    },
    lockVersion: null,
    statusCode: "",
    statusName: ""
  },
  newMailAddress: ""
};

const migrationModule = {
  mutations: {
    setAccountInfo(state, { accountInfo }) {
      state.entity = {
        ...state.entity,
        accountInfo: {
          ...(state.entity.accountInfo || {}),
          ...accountInfo
        }
      };
      console.log('account info set.')
    },
    setNewMailAddress(state, { newMailAddress }) {
      state.entity.newMailAddress = newMailAddress;
    },
    clearErrorMessage(state) {
      state.isError = false;
      state.errorMessages = [];
    }
  },
  actions: {
    /**
     * アカウント情報取得
     */
    getAccountInfo({ commit, dispatch, state }) {
      const store = this;

      commit("processing");
      console.log('get user info...')

      return new Promise((resolve, reject) => {
        clientWithoutAuth
          // store.$axios
          .get("/accounts/me")
          .then((response) => {
            if (response.data.data.accountInfoList[0].phoneNumberInfo) {
              response.data.data.accountInfoList[0].phoneNumberInfo.phoneNumber = `0${response.data.data.accountInfoList[0].phoneNumberInfo.phoneNumber}`;
              // } else {
              // response.data.data.accountInfoList[0].phoneNumberInfo = {
              //   phoneNumber: null,
              //   phoneCountryCode: "81"
              // };
            }
            commit("setAccountInfo", {
              accountInfo: response.data.data.accountInfoList[0]
            });
            commit("processed");
            resolve();
          })
          .catch((error) => {
            commit("failed", { error });
            commit("processed");
            reject();
          });
      });
    },
    /**
     * 確認コードの発行
     */
    migrationConfirmationCode(
      { commit, dispatch, state },
      { newMailAddress = undefined }
    ) {
      const store = this;

      const accountInfo = JSON.parse(JSON.stringify(state.entity.accountInfo));
      let data = {};
      let type = "";

      if (newMailAddress) {
        commit("setNewMailAddress", { newMailAddress });
        data["mailAddress"] = newMailAddress;
        type = "mailAddress";
      }

      commit("processing");

      // 新しいメールに向けて認証コードを発行
      return new Promise((resolve, reject) => {
        clientWithoutAuth
          // store.$axios
          .post(
            `/migration/${state.entity.accountInfo.accountId}/confirmationCode/${type}`,
            data
          )
          .then((response) => {
            commit("processed");
            resolve();
          })
          .catch((error) => {
            commit("failed", { error });
            commit("processed");
            reject();
          });
      });
    },
    /**
     * @param commit
     * @param state
     * @param firstName
     * @param lastName
     * @return {Promise<unknown>}
     */
    /**
     * メールアドレス変更
     */
    editMailAddress({ commit, dispatch, state }, { confirmationCode }) {
      const store = this;

      const confirmationCodeInfo = {
        confirmationCode,
        channel: "mail"
      };

      let data = {
        accountInfo: {
          ...JSON.parse(JSON.stringify(state.entity.accountInfo)),
          mailAddress: state.entity.newMailAddress
        },
        confirmationCodeInfo
      };

      if (data.accountInfo) {
        if (data.accountInfo.phoneNumberInfo) {
          const { phoneNumber } = data.accountInfo.phoneNumberInfo;
          if (phoneNumber) {
            data.accountInfo.phoneNumberInfo.phoneNumber = phoneNumber.substr(
              1
            );
          }
        }
      }

      commit("processing");

      return new Promise((resolve, reject) => {
        clientWithoutAuth
          // store.$axios
          .put(
            `/migration/accounts/${state.entity.accountInfo.accountId}/mailAddress`,
            data
          )
          .then((response) => {
            commit("processed");
            resolve();
          })
          .catch((error) => {
            commit("failed", { error });
            commit("processed");
            reject();
          });
      });
    },
    clearError({ commit, dispatch, state }) {
      commit("clearErrorMessage");
    },

    migrationStart(
      { commit, dispatch, state },
      { jreIdLoginFlag }
    ) {
      const store = this;

      const data = {
        jreIdLoginFlag
      };

      const url = `migration/accounts/${state.entity.accountInfo.accountId}/start`;
      console.log('url: ', url, data);
      // let type = "";
      // let newPhoneNumberInfo = {};
      // const accountInfo = JSON.parse(JSON.stringify(state.entity.accountInfo));

      commit("processing");

      return new Promise((resolve, reject) => {
        clientWithoutAuth
          // store.$axios
          .post(url, data)
          .then((response) => {
            commit("processed");
            console.log('response', response)
            resolve(response);
          })
          .catch((error) => {
            commit("failed", { error });
            commit("processed");
            reject();
          });
      });
    },

    migrationConfirm(
      { commit, dispatch, state },
      { associateTid, associateFlag }
    ) {
      const store = this;

      const data = {
        associateTid, associateFlag
      };

      const url = `migration/confirm`;
      console.log('url: ', url, data);

      commit("processing");

      return new Promise((resolve, reject) => {
        clientWithoutAuth
          // store.$axios
          .get(url, { params: data })
          .then((response) => {
            commit("processed");
            resolve(response);
          })
          .catch((error) => {
            commit("failed", { error });
            commit("processed");
            reject();
          });
      });
    }
  },
  getters: {
    accountInfo(state) {
      return state.entity.accountInfo;
    },
    mailAddress(state) {
      if (state.entity) {
        const { accountInfo } = state.entity;
        if (!accountInfo) {
          return "";
        } else {
          return state.entity.accountInfo.mailAddress;
        }
      }

      return "";
    },
    processing(state) {
      return !!state.processing;
    },
    isError(state) {
      return !!state.error;
    },
    errorMessages(state) {
      return state.errorMessages;
    },
    initialEntity() {
      return { ...initialEntity };
    },
    entity(state) {
      return state.entity || {};
    }
  }
};

const entityModule = entity("migration", initialEntity);

export const migration = {
  namespaced: true,
  modules: {
    entity: concatModules(entityModule, migrationModule)
  }
};

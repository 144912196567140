import router from "@/entries/front/router";
import { clientWithoutAuth } from "@/entries/front/axios";
import { entity, setOptionRefreshTrigger, clearDirty } from "./base";
import utility, { concatModules } from "@/utility";

const initialEntity = {
  count: null,
  accountInfo: {
    accountId: null,
    mailAddress: "",
    lastName: "",
    firstName: "",
    phoneNumberInfo: {
      phoneNumber: "",
      phoneCountryCode: ""
    },
    lockVersion: null,
    statusCode: "",
    statusName: ""
  },
  newPhoneNumberInfo: {
    newPhoneNumber: "",
    newPhoneCountryCode: ""
  },
  newMailAddress: "",
  oldPassword: "",
  newPassword: "",
  confirmPassword: ""
};

const accountModule = {
  mutations: {
    setAccountInfo(state, { accountInfo }) {
      state.entity = {
        ...state.entity,
        accountInfo: {
          ...(state.entity.accountInfo || {}),
          ...accountInfo
        }
      };
    },
    setNewMailAddress(state, { newMailAddress }) {
      state.entity.newMailAddress = newMailAddress;
    },
    setNewPhoneNumberInfo(state, { newPhoneNumberInfo }) {
      state.entity.newPhoneNumberInfo = {
        ...state.entity.newPhoneNumberInfo,
        ...newPhoneNumberInfo
      };
    },
    clearErrorMessage(state) {
      state.isError = false;
      state.errorMessages = [];
    }
  },
  actions: {
    /**
     * アカウント情報取得
     */
    getAccountInfo({ commit, dispatch, state }) {
      const store = this;

      commit("processing");

      return new Promise((resolve, reject) => {
        clientWithoutAuth
          // store.$axios
          .get("/accounts/me")
          .then((response) => {
            if (response.data.data.accountInfoList[0].phoneNumberInfo) {
              response.data.data.accountInfoList[0].phoneNumberInfo.phoneNumber = `0${response.data.data.accountInfoList[0].phoneNumberInfo.phoneNumber}`;
              // } else {
              // response.data.data.accountInfoList[0].phoneNumberInfo = {
              //   phoneNumber: null,
              //   phoneCountryCode: "81"
              // };
            }
            commit("setAccountInfo", {
              accountInfo: response.data.data.accountInfoList[0]
            });
            commit("processed");
            resolve();
          })
          .catch((error) => {
            commit("failed", { error });
            commit("processed");
            reject();
          });
      });
    },
    /**
     * 退会
     */
    withdrawal({ commit, dispatch, state }) {
      const store = this;

      commit("processing");

      return new Promise((resolve, reject) => {
        clientWithoutAuth
          // store.$axios
          .delete(`/accounts/${state.entity.accountInfo.accountId}`)
          .then((response) => {
            commit("processed");
            store.commit("done/displayableTrue");
            router.push({
              name: "done-index",
              params: {
                message:
                  "アカウント削除が完了しました。 The account has been deleted."
              }
            });
            resolve();
          })
          .catch((error) => {
            commit("failed", { error });
            commit("processed");
            reject();
          });
      });
    },
    /**
     * 確認コードの発行
     */
    issueConfirmationCode(
      { commit, dispatch, state },
      { newMailAddress = undefined, newPhoneNumber = undefined }
    ) {
      const store = this;

      const accountInfo = JSON.parse(JSON.stringify(state.entity.accountInfo));
      let data = {};
      let type = "";
      let newPhoneNumberInfo = {};

      if (newPhoneNumber) {
        if (!accountInfo.phoneNumberInfo) {
          accountInfo.phoneNumberInfo = {};
          accountInfo.phoneNumberInfo.phoneCountryCode = "81";
        }

        newPhoneNumberInfo = {
          newPhoneCountryCode: accountInfo.phoneNumberInfo.phoneCountryCode,
          newPhoneNumber
        };
        commit("setNewPhoneNumberInfo", {
          newPhoneNumberInfo
        });
        data["phoneNumberInfo"] = {
          phoneNumber: newPhoneNumberInfo.newPhoneNumber.substr(1),
          phoneCountryCode: accountInfo.phoneNumberInfo.phoneCountryCode
        };
        type = "phoneNumber";
      }

      if (newMailAddress) {
        commit("setNewMailAddress", { newMailAddress });
        data["mailAddress"] = newMailAddress;
        type = "mailAddress";
      }

      commit("processing");

      // 新しいメールまたは電話番号に向けて認証コードを発行
      return new Promise((resolve, reject) => {
        clientWithoutAuth
          // store.$axios
          .post(
            `/accounts/${state.entity.accountInfo.accountId}/confirmationCode/${type}`,
            data
          )
          .then((response) => {
            commit("processed");
            resolve();
          })
          .catch((error) => {
            commit("failed", { error });
            commit("processed");
            reject();
          });
      });
    },
    /**
     * ユーザー名変更
     */
    editUserName({ commit, dispatch, state }, { firstName, lastName }) {
      const store = this;

      const accountInfo = JSON.parse(JSON.stringify(state.entity.accountInfo));
      let data = { ...accountInfo };
      data.firstName = firstName;
      data.lastName = lastName;

      if (data.phoneNumberInfo) {
        const { phoneNumber } = data.phoneNumberInfo;
        if (phoneNumber) {
          data.phoneNumberInfo.phoneNumber = phoneNumber.substr(1);
        }
      }

      commit("processing");

      return new Promise((resolve, reject) => {
        clientWithoutAuth
          // store.$axios
          .put(`/accounts/${state.entity.accountInfo.accountId}`, data)
          .then((response) => {
            commit("processed");
            resolve();
          })
          .catch((error) => {
            commit("failed", { error });
            commit("processed");
            reject();
          });
      });
    },
    /**
     * パスワード更新
     * @param commit
     * @param state
     * @param firstName
     * @param lastName
     * @return {Promise<unknown>}
     */
    passwordUpdate({ commit, dispatch, state }, { oldPassword, newPassword }) {
      const store = this;

      const data = Object.assign(
        {},
        {
          oldPassword: oldPassword,
          newPassword: newPassword
        }
      );

      commit("processing");

      return new Promise((resolve, reject) => {
        clientWithoutAuth
          // store.$axios
          .put(`/accounts/${state.entity.accountInfo.accountId}/password`, data)
          .then((response) => {
            commit("processed");
            resolve();
          })
          .catch((error) => {
            commit("failed", { error });
            commit("processed");
            reject();
          });
      });
    },
    /**
     * 電話番号変更
     */
    editPhoneNumber({ commit, dispatch, state }, { confirmationCode }) {
      const store = this;

      const {
        newPhoneNumber,
        newPhoneCountryCode
      } = state.entity.newPhoneNumberInfo;
      const phoneNumberInfo = {
        phoneNumber: newPhoneNumber.substr(1),
        phoneCountryCode: newPhoneCountryCode
      };
      const confirmationCodeInfo = {
        confirmationCode,
        channel: "phone"
      };

      const data = {
        accountInfo: {
          ...JSON.parse(JSON.stringify(state.entity.accountInfo)),
          phoneNumberInfo
        },
        confirmationCodeInfo
      };

      commit("processing");

      return new Promise((resolve, reject) => {
        clientWithoutAuth
          // store.$axios
          .put(
            `/accounts/${state.entity.accountInfo.accountId}/phoneNumber`,
            data
          )
          .then((response) => {
            commit("processed");
            resolve();
          })
          .catch((error) => {
            commit("failed", { error });
            commit("processed");
            reject();
          });
      });
    },
    /**
     * メールアドレス変更
     */
    editMailAddress({ commit, dispatch, state }, { confirmationCode }) {
      const store = this;

      const confirmationCodeInfo = {
        confirmationCode,
        channel: "mail"
      };

      let data = {
        accountInfo: {
          ...JSON.parse(JSON.stringify(state.entity.accountInfo)),
          mailAddress: state.entity.newMailAddress
        },
        confirmationCodeInfo
      };

      if (data.accountInfo) {
        if (data.accountInfo.phoneNumberInfo) {
          const { phoneNumber } = data.accountInfo.phoneNumberInfo;
          if (phoneNumber) {
            data.accountInfo.phoneNumberInfo.phoneNumber = phoneNumber.substr(
              1
            );
          }
        }
      }

      commit("processing");

      return new Promise((resolve, reject) => {
        clientWithoutAuth
          // store.$axios
          .put(
            `/accounts/${state.entity.accountInfo.accountId}/mailAddress`,
            data
          )
          .then((response) => {
            commit("processed");
            resolve();
          })
          .catch((error) => {
            commit("failed", { error });
            commit("processed");
            reject();
          });
      });
    },
    signOut({ commit, rootGetters }) {
      const logout = () => {
        commit("processing");

        return new Promise((resolve, reject) => {
          clientWithoutAuth
            .delete(`/accounts/signout`)
            .then(() => {
              commit("processed");
              this.commit("done/displayableTrue");
              router.push({
                name: "done-index",
                params: {
                  message: "サインアウトしました。 Signed out successfully."
                }
              });
              resolve();
            })
            .catch((error) => {
              commit("failed", { error });
              commit("processed");
              reject();
            });
        });
      };

      if (rootGetters["form/isAnyDirty"]) {
        utility
          .$confirm("入力内容を破棄します。よろしいですか。", "確認", {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            type: "warning",
            center: true
          })
          .then(() => {
            commit("form/clearDirty", null, { root: true });
            logout();
          })
          .catch(() => { });
      } else {
        logout();
      }
    },
    clearError({ commit, dispatch, state }) {
      commit("clearErrorMessage");
    },
    migrationStart(
      { commit, dispatch, state },
      { jreIdLoginFlag }
    ) {
      const store = this;

      const data = {
        jreIdLoginFlag
      };

      const url = `migration/accounts/${state.entity.accountInfo.accountId}/start`;
      console.log('url: ', url, data);
      // let type = "";
      // let newPhoneNumberInfo = {};
      // const accountInfo = JSON.parse(JSON.stringify(state.entity.accountInfo));

      commit("processing");

      return new Promise((resolve, reject) => {
        clientWithoutAuth
          // store.$axios
          .post(url, data)
          .then((response) => {
            commit("processed");
            resolve(response);
          })
          .catch((error) => {
            commit("failed", { error });
            commit("processed");
            reject();
          });
      });
    }
  },
  getters: {
    accountInfo(state) {
      return state.entity.accountInfo;
    },
    mailAddress(state) {
      if (state.entity) {
        const { accountInfo } = state.entity;
        if (!accountInfo) {
          return "";
        } else {
          return state.entity.accountInfo.mailAddress;
        }
      }

      return "";
    },
    phoneNumber(state) {
      if (state.entity) {
        const { accountInfo } = state.entity;
        if (!accountInfo) {
          return "";
        } else {
          if (accountInfo.phoneNumberInfo) {
            return accountInfo.phoneNumberInfo.phoneNumber;
          }
        }
      }

      return "";
    },
    processing(state) {
      return !!state.processing;
    },
    isError(state) {
      return !!state.error;
    },
    errorMessages(state) {
      return state.errorMessages;
    },
    initialEntity() {
      return { ...initialEntity };
    },
    entity(state) {
      return state.entity || {};
    }
  }
};

const entityModule = entity("account", initialEntity);

export const accounts = {
  namespaced: true,
  modules: {
    entity: concatModules(entityModule, accountModule)
  }
};

import Vue from "vue";
import App from "@/entries/front/App.vue";
import router from "@/entries/front/router";
import store from "@/entries/front/store";
import "@/config";
import "@/plugins/element.js";
import vuetify from "@/plugins/vuetify";
import "@/plugins/fragment";
import "@/plugins/vee-validate";
import Navigation from "@/entries/front/components/app/navigation";
import CardMessage from "@/entries/front/components/message/card-message";
import anchor from "@/entries/front/components/field/anchor";
import BaseBtn from "@/entries/front/components/buttons/base-btn";
import MigrationLogo from "@/entries/front/components/logo/migration-logo";

Vue.config.productionTip = false;
//
Vue.component("navigation", Navigation);
Vue.component("card-message", CardMessage);
Vue.component("anchor", anchor);
Vue.component("base-btn", BaseBtn);
Vue.component("migration-logo", MigrationLogo);

// bootstrap
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount("#app");

import store from "@/entries/front/store";

const emailCheck = {
  params: ["excludedId"],
  validate: (value, { excludedId = undefined }) => {
    return store
      .dispatch(`users/entity/emailCheck`, { email: value, excludedId })
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
  },
  message: (field) => store.getters[`users/entity/errorMessages`].email[0]
};

const emailNotEqual = {
  params: [
    {
      name: "value2",
      isTarget: true
    }
  ],
  validate: (value, { value2 }) => {
    return value !== value2;
  },
  message:
    "現在のメールアドレスと異なるメールアドレスを入力してください。 Input a different email address from the current one."
};

const phoneNumberCheck = {
  params: [],
  validate: (value) => {
    return value.match(/^0\d{10}$/);
  },
  message:
    "有効な電話番号ではありません。日本国内のSMSが受信可能な電話番号を入力してください。 Input a Japanese mobile phone number to receive SMS."
};

const emailEndDomain = {
  params: [],
  validate: (value) => {
    return !value.toLowerCase().endsWith('@privaterelay.appleid.com');
  },
  message:
    "メール アドレスは @privaterelay.appleid.com で終わることはできません。The email address should not end with @privaterelay.appleid.com"
};

export { emailCheck, emailNotEqual, phoneNumberCheck, emailEndDomain };

<template>
  <ul
    style="display: flex; padding-left: 0px !important; align-items: center; justify-content: space-between; list-style-type: none;">
    <li><img :src="require('@/assets/logo.svg')" width="auto" height="67" /></li>
    <li><img :src="require('@/assets/right.png')" width="auto" height="40" /></li>
    <li><img :src="require('@/assets/jre-id.png')" width="130px" height="auto" /></li>
  </ul>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    logoSrc() {
      return "";
    }
  },
  methods: {}
};
</script>

<style module lang="scss">
@import "@/styles/common.scss";
$background-color: #f3f7f8;

.container {
  max-width: $app-width;
}

.footer {
  font-size: $font-size-root;
  color: rgba(0, 0, 0, 0.6) !important;
  background-color: $background-color !important;
}

.footer-row {
  max-width: $app-width !important;
  margin: 0 auto !important;
}
</style>

<style scoped lang="scss">
$background-color: #f3f7f8;

.nav-text {
  background-color: $background-color !important;

  img {
    &:hover {
      cursor: pointer !important;
    }
  }
}

.footer-nav-col {
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;

  &:first-child {
    border-left-width: 0;
  }

  &:last-child {
    border-right-width: 0;
  }
}
</style>